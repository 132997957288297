import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { Lecture } from '../models/lecture';
import { LecturePackage } from '../models/lecture-package';
import { Question } from '../models/question';
import { Serial } from '../models/serial';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  







  base_path = 'http://52.46.167.72.host.secureserver.net:8080/api';
  // base_path = 'http://localhost:8080/api';


  constructor(private http: HttpClient) { }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };


  // Create a new item
  addLecture(item: Lecture) {
    const requestOptions: Object = {
      headers: this.httpOptions.headers,
      responseType: 'text'
    }
    return this.http.post(this.base_path + "/lecture/add", JSON.stringify(item), requestOptions);

  }

  getLectures(academicYear: string): Observable<Lecture[]> {
    var requestHeader = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'academicYear': academicYear
        })
    };
    return this.http
      .get<Lecture[]>(this.base_path + '/lecture/get-all', requestHeader)
      .pipe(
        catchError(this.handleError)
      )
  }



  // Update item by id
  updateItem(item): Observable<Lecture> {
    return this.http
      .put<Lecture>(this.base_path + '/', JSON.stringify(item), this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  // Delete item by id
  deleteItem(id) {
    return this.http
      .delete<Lecture>(this.base_path + '/' + id, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  generateSerials(serialsCount: string) {
    var requestHeader = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'numberOfSerials': serialsCount
        })
    };
    return this.http.get(this.base_path + "/serial-number/generate", requestHeader).pipe(
      catchError(this.handleError)
    )
  }
  getPassword(email: string) {
    var requestHeader = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'email': email
        })

    };
    const requestOptions: Object = {
      headers: requestHeader.headers,
      responseType: 'text'
    }
    console.log(requestOptions);

    return this.http.get(this.base_path + "/profile/get-password", requestOptions).pipe(
      catchError(this.handleError)
    )
  }

  getLectureQuestions(id: string) {
    var requestHeader = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'lectureId': id
        })
    };
    return this.http.get<Question[]>(this.base_path + "/question/getByLecture", requestHeader).pipe(
      catchError(this.handleError)
    )
  }


  addQuestion(question: Question) {
    var requestHeader = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        })
    };
    return this.http.post<Question>(this.base_path + "/question/add", JSON.stringify(question), requestHeader).pipe(
      catchError(this.handleError)
    )
  }

  removeQuestionAPI(question: Question) {
    var requestHeader = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          responseType: 'text'
        })
    };
    return this.http.delete<Question>(this.base_path + "/question/delete/" + question.id, requestHeader).pipe(
      catchError(this.handleError)
    )
  }

  login(loginRequest: any) {
    var requestHeader = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'platformKey': 'ioouhdsdp[sda'
        })
    };
    return this.http.post(this.base_path + "/dashboard/signin", loginRequest, requestHeader).pipe(
      catchError(this.handleError)
    )
  }

  getProfile(email: string) {
    var requestHeader = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'email': email
        })

    };
    const requestOptions: Object = {
      headers: requestHeader.headers
    }

    return this.http.get<User>(this.base_path + "/dashboard/get-profile", requestOptions).pipe(
      catchError(this.handleError)
    )
  }


  removeUserSession(email: string) {
    var requestHeader = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          responseType: 'text'
        })

    };
    const requestOptions: Object = {
      headers: requestHeader.headers
    }

    return this.http.get(this.base_path + "/dashboard/removeUserSession/" + email, requestOptions).pipe(
      catchError(this.handleError)
    )
  }

  getSerialInfo(serialText: string) {
    var requestHeader = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        })

    };
    const requestOptions: Object = {
      headers: requestHeader.headers
    }

    return this.http.get<Serial>(this.base_path + "/serial-number/info/" + serialText, requestOptions).pipe(
      catchError(this.handleError)
    )
  }
  deleteAccount(email: string) {
    var requestHeader = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          responseType: 'text'
        })

    };
    const requestOptions: Object = {
      headers: requestHeader.headers
    }

    return this.http.delete(this.base_path + "/dashboard/delete-account/" + email, requestOptions).pipe(
      catchError(this.handleError)
    )
  }

  getPackagesByAcademicYear(academicYear : string){
    var requestHeader = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'academicYear': academicYear
        })
    };
    return this.http
      .get<LecturePackage[]>(this.base_path + '/package/get-all', requestHeader)
      .pipe(
        catchError(this.handleError)
      )
  }

  addPackage(lecturePackage : LecturePackage){

    const requestOptions: Object = {
      headers: this.httpOptions.headers,
      responseType: 'text'
    }
    return this.http.post(this.base_path + "/package/add-lectures-package", JSON.stringify(lecturePackage), requestOptions);
  }
  generatePackageSerials(serialsCount: string,packageId : string) {
    var requestHeader = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'numberOfSerials': serialsCount,
          'packageId':packageId
        })
    };
    return this.http.get(this.base_path + "/package/generate-package-lecture-serial", requestHeader).pipe(
      catchError(this.handleError)
    )
  }

  hideLecture(lecture : Lecture){
    const requestOptions: Object = {
      headers: this.httpOptions.headers,
      responseType: 'text'
    }
    return this.http.post(this.base_path + "/lecture/hide-lecture", JSON.stringify(lecture), requestOptions);
  }

  getPackageLectures(packageId: string): Observable<Lecture[]> {
    var requestHeader = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'packageId': packageId
        })
    };
    return this.http
      .get<Lecture[]>(this.base_path + '/lecture/get-package-lectures', requestHeader)
      .pipe(
        catchError(this.handleError)
      )
  }

  hidePackage(lecturePackage : LecturePackage){
    const requestOptions: Object = {
      headers: this.httpOptions.headers,
      responseType: 'text'
    }
    return this.http.post(this.base_path + "/package/hide-package", JSON.stringify(lecturePackage), requestOptions);
  }

}
