import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Lecture } from '../models/lecture';
import { Question } from '../models/question';
import { ApiService } from '../services/api.service';


@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {

  public rateControl : any;
  public lectureId : string;
  public questions : Question[];
  constructor(private route: ActivatedRoute,private router: Router,public apiService: ApiService){
    this.route.params.subscribe( params => this.lectureId=params['lectureId'] );

      console.log(this.lectureId);
      this.getLectureQuestions(this.lectureId);

  }

  ngOnInit(){
   
  }
  

  getLectureQuestions(lectureId : string){
    this.apiService.getLectureQuestions(lectureId).subscribe(response => {
      this.questions=response;
      this.questions.forEach(question=> {
        question.isEditQuestion = true;
      })
    })

  }

  
  editQuestion(question : Question){
    question.isEditQuestion=false;
  }

  removeQuestion(question : Question){
    console.log(question);
    if(confirm("Are you sure to delete ?")) {
      this.apiService.removeQuestionAPI(question).subscribe(response => {
        console.log(response);
        window.location.reload();
        
      })
    }
    
  }

  saveQuestion(question : Question){
    this.apiService.addQuestion(question).subscribe(response => {
      console.log(response);
      // this.router.navigate(['/questions'],{queryParams:this.lecture});
      window.location.reload();

    })
    }
  cancel(question : Question){
    question.isEditQuestion=true;
  }

  addQuestionScreen(lectureId : string){
    console.log(lectureId)
    this.router.navigate(['/add-question/'+lectureId]);
  }
}
