import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Lecture } from '../models/lecture';
import { Question } from '../models/question';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.scss']
})
export class AddQuestionComponent implements OnInit {

  public lectureId : string;
  public question : Question;
  constructor(private route: ActivatedRoute,private router: Router,public apiService: ApiService){
    this.route.params.subscribe( params => this.lectureId=params['lectureId'] );

    console.log(this.lectureId);
  }

  ngOnInit(){
    
  }

  onSubmit(form: NgForm){
    this.question=form.value;
    console.log(this.question);
    
    this.question.lectureId=this.lectureId;
    this.apiService.addQuestion(this.question).subscribe(response => {
      this.router.navigate(['/questions/'+this.lectureId]);
    })
  }

}
