import { JsonPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Lecture } from '../models/lecture';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-update-lecture',
  templateUrl: './update-lecture.component.html',
  styleUrls: ['./update-lecture.component.scss']
})
export class UpdateLectureComponent implements OnInit {

  public lecture : any;
  public updatedLecture : Lecture;
  constructor(private route: ActivatedRoute,private router: Router,public apiService: ApiService) {
    
    
  }

  ngOnInit() {
    this.route.data.subscribe( data =>       
      this.lecture = JSON.parse(this.route.snapshot.paramMap.get('lecture'))
      );   
      console.log(this.lecture);
         
  }

  onSubmit(form: NgForm) {
    this.lecture=form.value;
    console.log(this.lecture);
    
      this.apiService.addLecture(this.lecture).subscribe(response => {
        console.log(response);
        if(this.lecture.packageId===null){
          this.router.navigate(['/lectures/'+this.lecture.academicYear]);
        }else{
          this.router.navigate(['/packages']);
        }
      })
      return;
    
   
  }
}
