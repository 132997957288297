import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Lecture } from '../models/lecture';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-add-lecture',
  templateUrl: './add-lecture.component.html',
  styleUrls: ['./add-lecture.component.scss']
})
export class AddLectureComponent implements OnInit {
  public grade : any;
  public packageId : any;
  ngOnInit() {
  }
  constructor(public apiService : ApiService,private route: ActivatedRoute,private router: Router){
    this.route.params.subscribe( params => this.grade=params['grade'] );
    this.route.params.subscribe( params => this.packageId=params['packageId'] );
  }
  public lecture: Lecture;
  onSubmit(form: NgForm) {
     this.lecture=form.value;
     this.lecture.academicYear=this.grade;
     if(this.packageId==='null'){

     }else{
      this.lecture.packageId=this.packageId;
     }
     this.lecture.hideLecture=false;
     console.log(this.lecture);
     
      this.apiService.addLecture(this.lecture).subscribe(response => {
        console.log(response);
        if(this.packageId==='null'){
          this.router.navigate(['/lectures/'+this.grade]);
        }else{
          this.router.navigate(['/packages']);
        }
      })
    

}
}
