import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-generate-package-serials',
  templateUrl: './generate-package-serials.component.html',
  styleUrls: ['./generate-package-serials.component.scss']
})
export class GeneratePackageSerialsComponent {
  public serialsCount : string;
  public serials : any;
  public header : string;
  public packageId : string;
  ngOnInit() {
    this.header="  ادخل عدد السريال المطلوبة";
  }
  constructor(private route: ActivatedRoute,public apiService : ApiService,private router: Router ){
    this.route.params.subscribe( params => this.packageId=params['packageId'] );

  }
 

  onSubmit(form: NgForm) {
    this.serialsCount=form.value.serialsCount;
    this.apiService.generatePackageSerials(this.serialsCount,this.packageId).subscribe(response => {
      this.serials=response;
      this.header="";
      this.downloadFile(this.serials);
    })
  }
  downloadFile(data: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  }
  generateNew(){
    window.location.reload();
  }
}
