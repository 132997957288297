import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Serial } from '../models/serial';
import { User } from '../models/user';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-serial-info',
  templateUrl: './serial-info.component.html',
  styleUrls: ['./serial-info.component.scss']
})
export class SerialInfoComponent {
  public serialText : string;
  public header : string;
  public serialExist : boolean;
  public serial : Serial;
  public serialUser : User;
  public serialNotExist: boolean;
  ngOnInit() {
    this.header="  ادخل  السريال ";
  }
  constructor(public apiService : ApiService,private router: Router ){
    this.serialExist=false;
    this.serialNotExist=true;
  }
 

  onSubmit(form: NgForm) {
    this.serialText=form.value.serialText;
    this.apiService.getSerialInfo(this.serialText).subscribe(response => {
      console.log(response)
      this.serial=response;
      this.header="";
      if(this.serial.email!==null){
        this.serial.activationDate=this.serial.activationDate.replace("T","-")
        this.apiService.getProfile(this.serial.email).subscribe(response => {
          this.serialUser=response
          this.serialExist=true;
        }
        )
      }else{
        this.header="هذا السريال لم يستخدم من قبل"
      }
    },(err)=>{
      console.log(err);
      this.serialNotExist=false;
      this.header="هذا السريال غير موجود بالسيستم";
    })
  }
  getImage(imageUrl : string){
    var url= this.apiService.base_path+"/profile"+imageUrl;
    return url;
    
  }
  generateNew(){
    window.location.reload();
  }
}
