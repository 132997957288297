import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { AddLectureComponent } from './add-lecture/add-lecture.component';
import { FormsModule } from '@angular/forms';
import { LecturesComponent } from './lectures/lectures.component';
import { UpdateLectureComponent } from './update-lecture/update-lecture.component';
import { SerialComponent } from './serial/serial.component';
import { StudentComponent } from './student/student.component';
import { QuestionComponent } from './question/question.component';
import { AddQuestionComponent } from './add-question/add-question.component';
import { MockHttpCalIInterceptor } from './http.interceptor';
import { SerialInfoComponent } from './serial-info/serial-info.component';
import { PackagesComponent } from './packages/packages.component';
import { GradePackagesComponent } from './grade-packages/grade-packages.component';
import { PackageLecturesDetailsComponent } from './package-lectures-details/package-lectures-details.component';
import { AddPackageComponent } from './add-package/add-package.component';
import { GeneratePackageSerialsComponent } from './generate-package-serials/generate-package-serials.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule
    ],
    declarations: [AppComponent, AddLectureComponent,LecturesComponent, UpdateLectureComponent, SerialComponent, StudentComponent, QuestionComponent, AddQuestionComponent, SerialInfoComponent, PackagesComponent, GradePackagesComponent, PackageLecturesDetailsComponent, AddPackageComponent, GeneratePackageSerialsComponent],
    providers: [AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MockHttpCalIInterceptor,
            multi: true
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
