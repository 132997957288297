import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../models/user';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss']
})

export class StudentComponent implements OnInit{
  public email: String;
  public form : any;
  public hideInput : boolean;
  public user : User;
  public userNotExist:boolean;
  constructor(private route: ActivatedRoute, private router: Router, public apiService: ApiService) {
    this.route.params.subscribe();
    this.userNotExist=false;
  }

  ngOnInit() {
    this.hideInput=true;
  }

  onSubmit(form: NgForm) {
    this.form = form.value;
    this.userNotExist=false;
    this.hideInput=true;
    this.apiService.getProfile(this.form.email).subscribe(response => {
      this.hideInput=false;
      response.academicYear=this.setGradeDesc(response.academicYear);
      this.user=response;      
    },(err)=>{
      console.log(err)
      this.userNotExist=true;

    });
  }
  public setGradeDesc(grade : string){
    if(grade==="FRIST_ACADEMIC_YEAR") {
      return "الصف الاول ";
    }
    if(grade==="SECOND_ACADEMIC_YEAR") {
      return "الصف الثاني ";
    }
    if(grade==="THIRD_ACADEMIC_YEAR") {
      return "الصف الثالث ";
    }
  }
  getImage(imageUrl : string){
    var url= this.apiService.base_path+"/profile"+imageUrl;
    return url;
    
  }
  removeUserSession(email : string){
    if(confirm(" هذه الخطوة ستجعل الطالب يقوم بتسجيل الدخول من جديد ... استمرار ؟ ")) {

    this.apiService.removeUserSession(email).subscribe(response => {
      
      confirm("تمت العملية بنجاح")      
    });
  }
  } 

  deleteStudent(email : string){
    if(confirm(" هذه الخطوة ستمسح البروفايل الخاص بالطالب ... استمرار ؟ ")) {

    this.apiService.deleteAccount(email).subscribe(response => {
      window.location.reload();
      
      confirm("تمت مسح بروفايل الطالب بنجاح ")      
    });
  }
  }
}
