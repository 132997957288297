import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Lecture } from '../models/lecture';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-package-lectures-details',
  templateUrl: './package-lectures-details.component.html',
  styleUrls: ['./package-lectures-details.component.scss']
})
export class PackageLecturesDetailsComponent {
  lecturesList : Lecture[];
  packageId : string;
  grade : string;
  constructor(private route: ActivatedRoute,private router: Router,public apiService: ApiService) {
    this.route.params.subscribe( params => this.packageId=params['packageId'] );
    this.route.params.subscribe( params => this.grade=params['grade'] );
  }

  ngOnInit() {
    this.getPackageLectures(this.packageId);
  }

  public getPackageLectures(packageId : string){
    this.apiService.getPackageLectures(packageId).subscribe(response => {
      this.lecturesList = response;
      console.log(this.lecturesList);
    })
  }

  public addLecture(packageId : string){
    console.log(packageId);
    this.router.navigate([`/addLecture/`+this.grade+`/package/`+packageId]);
  }

  public updateLecture(lecture : Lecture){
    this.router.navigate([`/updateLecture`,{lecture: JSON.stringify(lecture)}]);

  }

  public lectureQuestions(lecture : Lecture){
    this.router.navigate(['/questions/'+lecture.id]);
  }
  public hideLecture(lecture : Lecture){
    this.apiService.hideLecture(lecture).subscribe(response => {
      window.location.reload();
    })
  }

  public getButtonText(lecture : Lecture){
    if(lecture.hideLecture===true){
      return "عدم اخفاء المحاضرة" ;
    }
    return " مسح المحاضرة";
  }

  public setButtonClass(lecture : Lecture){
    if(lecture.hideLecture===true){
      return "btn btn-success" ;
    }
    return "btn btn-danger";
  }

}
