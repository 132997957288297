import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Lecture } from '../models/lecture';
import { LecturePackage } from '../models/lecture-package';
import { ApiService } from '../services/api.service';


@Component({
  selector: 'app-grade-packages',
  templateUrl: './grade-packages.component.html',
  styleUrls: ['./grade-packages.component.scss']
})
export class GradePackagesComponent {
  public grade : any;
  public gradeDesc : string ;
  packageList : LecturePackage[];
  constructor(private route: ActivatedRoute,private router: Router,public apiService: ApiService) {
    this.route.params.subscribe( params => this.grade=params['grade'] );
    this.setGradeDesc(this.grade);
   
    
  }

  ngOnInit() {
    this.getPackages(this.grade);

  }

  public getPackages(grade : string){
    this.apiService.getPackagesByAcademicYear(grade).subscribe(response => {
      this.packageList = response;
    })
  }
  public setGradeDesc(grade : string){
    if(grade==="FRIST_ACADEMIC_YEAR") {
      this.gradeDesc="الصف الاول ";
    }
    if(grade==="SECOND_ACADEMIC_YEAR") {
      this.gradeDesc="الصف الثاني ";
    }
    if(grade==="THIRD_ACADEMIC_YEAR") {
      this.gradeDesc="الصف الثالث ";
    }
  }
  
  public viewPackageLectures(packageId: string){
    this.router.navigate(['packageLecturesDetails/'+packageId+'/grade/'+this.grade]);
  }

  public addPackage(grade : string){
    this.router.navigate(['addPackage/grade/'+grade]);
  }

  public generatePackageSerails(packageId : string){
    this.router.navigate(['generatePackageSerials/'+packageId]);
  }

  public hidepackage(lecturePackage : LecturePackage){
    console.log(lecturePackage);
    
    this.apiService.hidePackage(lecturePackage).subscribe(response => {
      window.location.reload();
    })
  }

  public getButtonText(lecturePackage : LecturePackage){
    if(lecturePackage.hidePackage===true){
      return "عدم اخفاء الباكدج" ;
    }
    return " اخفاء الباكدج";
  }

  public setButtonClass(lecturePackage : LecturePackage){
    if(lecturePackage.hidePackage===true){
      return "btn btn-success" ;
    }
    return "btn btn-danger";
  }
}
