import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LecturePackage } from '../models/lecture-package';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-add-package',
  templateUrl: './add-package.component.html',
  styleUrls: ['./add-package.component.scss']
})
export class AddPackageComponent {
  public grade : any;
  public lecturePackage : LecturePackage;
  ngOnInit() {
  }
  constructor(public apiService : ApiService,private route: ActivatedRoute,private router: Router){
    this.route.params.subscribe( params => this.grade=params['grade'] );
  }
  onSubmit(form: NgForm) {
     this.lecturePackage=form.value;
     this.lecturePackage.academicYear=this.grade;
     this.lecturePackage.hidePackage=false;
     console.log(this.lecturePackage);
     
      this.apiService.addPackage(this.lecturePackage).subscribe(response => {
        console.log(response);
        
          this.router.navigate(['/packages']);
        
      })
    

}
}
