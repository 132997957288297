import { Component, NgModule, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { Lecture } from '../models/lecture';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-lectures',
  templateUrl: './lectures.component.html',
  styleUrls: ['./lectures.component.scss']
})

export class LecturesComponent implements OnInit {
  
  public grade : any;
  public gradeDesc : string ;
  lecturesList : Lecture[];
  constructor(private route: ActivatedRoute,private router: Router,public apiService: ApiService) {
    this.route.params.subscribe( params => this.grade=params['grade'] );
    this.setGradeDesc(this.grade);
   
    
  }

  ngOnInit() {
    this.getLecture(this.grade);

  }

  public getLecture(grade : string){
    this.apiService.getLectures(grade).subscribe(response => {
      this.lecturesList = response;
      console.log(this.lecturesList);
    })
  }
  public setGradeDesc(grade : string){
    if(grade==="FRIST_ACADEMIC_YEAR") {
      this.gradeDesc="الصف الاول ";
    }
    if(grade==="SECOND_ACADEMIC_YEAR") {
      this.gradeDesc="الصف الثاني ";
    }
    if(grade==="THIRD_ACADEMIC_YEAR") {
      this.gradeDesc="الصف الثالث ";
    }
  }

  public addLecture(grade : string){
    this.router.navigate([`/addLecture/`+grade+'/package/null']);
  }

  public updateLecture(lecture : Lecture){
    this.router.navigate([`/updateLecture`,{lecture: JSON.stringify(lecture)}]);

  }

  public lectureQuestions(lecture : Lecture){
    this.router.navigate(['/questions/'+lecture.id]);
  }

  public hideLecture(lecture : Lecture){
    this.apiService.hideLecture(lecture).subscribe(response => {
      window.location.reload();
    })
  }

  public getButtonText(lecture : Lecture){
    if(lecture.hideLecture===true){
      return "عدم اخفاء المحاضرة" ;
    }
    return "مسح المحاضرة";
  }

  public setButtonClass(lecture : Lecture){
    if(lecture.hideLecture===true){
      return "btn btn-success" ;
    }
    return "btn btn-danger";
  }
}
